import { template as template_da638a47fd284f8a9ca9672bbf0c455a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_da638a47fd284f8a9ca9672bbf0c455a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
