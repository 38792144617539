import { template as template_8d2e928bfda54f46a56529e969054184 } from "@ember/template-compiler";
const WelcomeHeader = template_8d2e928bfda54f46a56529e969054184(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
