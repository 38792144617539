import { template as template_02eee027f8a748f2aa9d346ff25fb541 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_02eee027f8a748f2aa9d346ff25fb541(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
