import { template as template_dc95b392e22f4293a55a92d6f4b52df0 } from "@ember/template-compiler";
const FKText = template_dc95b392e22f4293a55a92d6f4b52df0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
