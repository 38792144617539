import { template as template_f17200e14c814cf9a22285f4c827cdeb } from "@ember/template-compiler";
const FKLabel = template_f17200e14c814cf9a22285f4c827cdeb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
